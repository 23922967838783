// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'

import { defineComponent } from 'vue';
const routes = [
  {
    path: '/t2',
    name: 't2',
    components:defineComponent  (() =>  import('./views/About.vue'))
  },
  {
    path: '/about',
    name: 'about',
    components:defineComponent  (() =>  import('./views/About.vue'))
  },
  {
    path: '/t3',
    name: 't3',
    components:defineComponent  (() =>  import('./views/About.vue'))
  },
]




window.chidCms = (router)=>{
	// console.log(router.getRoutes())

	var temp= router.getRoutes()
	// var t =''
	// for(var i =0;i<temp.length;i++){
	// 	if(temp[i].name=='system-user'){
	// 		t= router.getRoutes()[i].components 
	// 	}
	// }
	// console.log(t)
	// console.log(routes[0].component)
	for(var i =0;i<temp.length;i++){

		for(var b=0;b<routes.length;b++){
			if(temp[i].name==routes[b].name){
				router.getRoutes()[i].components = {
					default:routes[b].components.setup
				}
			}
		}
		// if(temp[i].name=='about' || temp[i].name=='t3'){
			
		// }
	}
	
}
// createApp(App).use(router).mount('#app')
